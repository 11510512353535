import React from "react";
export const Header = (props) => {
  return (
    <div className="intro">
      <header id="header">
        <video autoPlay loop muted className="background-video">
          <source src="/img/tax.mp4" type="video/mp4" />
          Tu navegador no soporta la etiqueta de video.
        </video>
        <div className="overlay">
          <div className="container">
            <div className="row">
              
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

